<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.sendWhatsApp">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Отправить WhatsApp ({{ this.userGet.full_name }}) - {{ this.userGet.phone }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="col-form-label">Сообщение</label>
              <textarea class="form-control" placeholder="Сообщение" v-model="whats.sms" rows="4"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Закрыть</button>
            <button type="button" class="btn btn-primary" @click="sendWhatsApp">Отправить</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'send-whats-app',
  props: ['id'],
  data: function () {
    return {
      user: [],
      whats: {
        users_id: 0,
        sms: '',
        score: null
      }
    }
  },
  computed: {
    ...mapGetters(['userGet']),
  },
  watch: {
    id: function (val) {
      if(val > 0){
        this.whats.users_id = val;
        this.getUser();
      }
    },
  },
  methods: {
    sendWhatsApp: function(){
      this.$store.dispatch('sendWhatsApp', this.whats).then(() => {
        this.whats.sms = '';
        this.close();
      });
    },
    getUser: function(){
      this.$store.dispatch('getUser', this.whats.users_id);
    },
    close: function() {
      this.$store.state.modal.sendWhatsApp = false;
    },
  }
}
</script>