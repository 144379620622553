<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список заказов ({{ this.orderCount }})</div>
      <div class="btn-group">
        <a class="btn btn-secondary" @click="showFilter">Показать фильтры</a>
        <router-link to="/create-order" class="btn btn-success">Создать заказ</router-link>
      </div>
    </div>
    <div class="form-filter low fs-12" @keyup.enter="getOrderList" v-if="visibleFalse">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Номер заказа
              <vue-custom-tooltip label="Номер заказа seasonmarket или ecomarket"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <input type="text" class="form-control" placeholder="Номер заказа" v-model="filter.orderId">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Поиск
              <vue-custom-tooltip label="Номер телефона, ФИО, адрес"><i class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <input type="text" class="form-control" v-model="filter.search" placeholder="Поиск">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Статус заказа</label>
            <select class="form-control" v-model="filter.status">
              <option value="">Выберите статус</option>
              <option value="created">Создан</option>
              <option value="handed_over_for_picking">Собран</option>
              <option value="on_the_way">Доставляется</option>
              <option value="delivered">Доставлен</option>
              <option value="canceled">Отменен</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Тип оплаты</label>
            <select class="form-control" v-model="filter.type_payment">
              <option value="">Выберите тип</option>
              <option value="1">Наличные</option>
              <option value="3">Безналичные</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Статус оплаты</label>
            <select class="form-control" v-model="filter.status_payment">
              <option value="">Выберите тип</option>
              <option value="0">Не оплачен</option>
              <option value="1">Частично оплачен</option>
              <option value="2">Оплачен</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Источник</label>
            <select class="form-control" v-model="filter.source">
              <option value="">Выберите источник</option>
              <option value="0">Сайт</option>
              <option value="1">Приложение</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Дата от</label>
            <input type="date" class="form-control" placeholder="Дата от" v-model="filter.dateCreateBefore">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Дата до</label>
            <input type="date" class="form-control" placeholder="Дата до" v-model="filter.dateCreateAfter">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Дата доставки</label>
            <input type="date" class="form-control" placeholder="Дата доставки" v-model="filter.dateDelivery">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="btn-group mt-2">
          <button type="button" class="btn btn-success" @click="getOrderList">Найти</button>
        </div>
      </div>
    </div>
    <a class="btn btn-success mt-2 mb-2" @click="exportExcel">XLSX</a>
    <p class="float-end mt-2 mb-2">Показано
      <span v-if="this.filter.limit > this.orderCount">
        {{ this.orderCount }}
      </span>
      <span v-else>{{ this.filter.limit }}</span>
      из {{ this.orderCount }}</p>
    <table class="table table-hover table-bordered table-striped fs-12">
      <thead>
      <tr>
        <th scope="col" class="text-center">№</th>
        <th scope="col" class="text-center">Дата заказа</th>
        <th scope="col" class="text-center">Дата доставки</th>
        <th scope="col" class="text-center">Пользователь</th>
        <th scope="col" class="text-center">Cумма заказа</th>
        <th scope="col" class="text-center">Стоимость доставки</th>
        <th scope="col" class="text-center">Промокод</th>
        <th scope="col">Адрес</th>
        <th scope="col">Комментарий к заказу</th>
        <th scope="col">Курьер</th>
      </tr>
      </thead>
      <tbody v-if="orderList.length > 0">
      <tr v-for="item in orderList" :key="item.id">
        <td class="w-150 text-center">
          <router-link class="text-primary" :to="'/view-order/' + item.id">{{ item.id }}</router-link>
          <br>
          ({{ item.eco_id }}
          <vue-custom-tooltip label="Номер заказа на ecomarket.ru"><i class="far fa-question-circle"></i>
          </vue-custom-tooltip>
          )<br>
          <span class="badge bg-secondary" v-if="item.status_payment == 0">Не оплачен</span>
          <span class="badge bg-info" v-else-if="item.status_payment == 1">Средства зарезервированы</span>
          <span class="badge bg-success" v-else-if="item.status_payment == 2">Оплачен</span>
          <br>
          <span class="badge bg-info" v-if="item.status == 'created'">Создан</span>
          <span class="badge bg-warning" v-else-if="item.status == 'handed_over_for_picking'">Собран</span>
          <span class="badge bg-secondary" v-else-if="item.status == 'on_the_way'">Доставляется</span>
          <span class="badge bg-success" v-else-if="item.status == 'delivered'">Доставлен</span>
          <span class="badge bg-danger" v-else-if="item.status == 'canceled'">Отменен</span>
          <br>
          <i class="fas fa-mobile-alt" v-if="item.source == 1"></i>
          <br>
          <p v-if="item.region == 77">Москва и Московская область</p>
          <p v-else-if="item.region == 78">Санкт-Петербург и Ленинградская область</p>
        </td>
        <td class="w-150 text-center">
          {{ item.date }}
          <p v-if="item.when_order == 0"><strong>Сегодня</strong></p>
          <p v-else-if="item.when_order == -1"><strong>Вчера</strong></p>
          <p v-else-if="item.when_order == -2"><strong>Позавчера</strong></p>
        </td>
        <td class="w-150 text-center">
          {{ item.date_interval }}
          {{ item.time_interval }}
          <p v-if="item.when_delivery == 0"><strong>Сегодня</strong></p>
          <p v-else-if="item.when_delivery == 1"><strong>Завтра</strong></p>
          <p v-else-if="item.when_delivery == 2"><strong>Послезавтра</strong></p>
          <p v-else-if="item.when_delivery > 2"><strong>Через {{ item.when_delivery }} {{ num2word(item.when_delivery, ['день', 'дня', 'дней'])}}</strong></p>
          <p v-else-if="item.when_delivery < 0"><strong>Дней назад {{ item.when_delivery.toString().replace('-', '') }} </strong></p>
        </td>
        <td class="text-center">
          <span class="badge bg-warning" v-if="(parseInt(item.users.countOrder) + parseInt(item.users.total_count_order)) == 1">Первый заказ клиента</span>
          <br v-if="(parseInt(item.users.countOrder) + parseInt(item.users.total_count_order)) == 1">
          <router-link :to="'/view-user/' + item.users_id">
            <strong class="text-primary">{{ item.users.full_name }}</strong>
          </router-link>
          <p>{{ item.users.phone }}</p>
          <p>{{ item.users.email }}</p>
          <br>
          <p class="text-primary pointer" @click="openSmsModal(item.users_id)">Отправить СМС</p>
          <p class="text-primary pointer" @click="openWhatsAppModal(item.users_id)">Отправить WhatsApp</p>
        </td>
        <td class="text-center">
          {{ parseFloat(item.amount).toFixed(1) }} руб.<br>
          <p v-if="item.type_payment == 1">Наличные</p>
          <p v-else>Безналичные</p>
        </td>
        <td class="text-center" v-if="item.delivery_price == 0">Бесплатно</td>
        <td class="text-center" v-else>{{ item.delivery_price }} руб.</td>
        <td class="text-center" v-if="item.code !== null">{{ item.code }}</td>
        <td class="text-center" v-else>-</td>
        <td>
          {{ item.address }}<br>
          <a class="link-primary"
             :href="'https://yandex.ru/maps/?ll=' + item.lon + '%2C' + item.lat + '&mode=whatshere&whatshere%5Bpoint%5D=' + item.lon + '%2C' + item.lat + '&whatshere%5Bzoom%5D=11.97&z=7.78'"
             target="_blank">{{ item.lat + ' ' + item.lon }}</a>
        </td>
        <td>{{ item.comment }}</td>
        <td>
          <p>{{ item.courier }}</p>
          <p>{{ item.courier_phone }}</p>
        </td>
      </tr>
      </tbody>
      <tbody v-else>Ничего не найдено</tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore" v-if="this.orderCount > 0 && this.filter.limit < this.orderCount">Загрузить
          ещё</a>
      </div>
    </div>
    <send-sms :id="selectedUser"/>
    <send-whats-app :id="selectedUser"/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SendSms from "../../components/modal/users/send-sms";
import SendWhatsApp from "../../components/modal/users/send-whats-app";
export default {
  name: 'list-orders',
  components: {SendWhatsApp, SendSms},
  data: function () {
    return {
      filter: {
        limit: 12,
        status: '',
        search: '',
        orderId: '',
        type_payment: '',
        source: '',
        dateCreateBefore: '',
        dateCreateAfter: '',
        dateDelivery: '',
        status_payment: ''
      },
      selectedUser: 0,
      visibleFalse: false,
    }
  },
  computed: {
    ...mapGetters(['orderList', 'orderCount']),
  },
  async mounted() {
    this.getOrderList();
  },
  methods: {
    showFilter: function () {
      this.visibleFalse = !this.visibleFalse;
    },
    num2word: function(num, words){
      num = num % 100;
      if (num > 19) {
        num = num % 10;
      }
      switch (num) {
        case 1: {
          return(words[0]);
        }
        case 2: case 3: case 4: {
          return(words[1]);
        }
        default: {
          return(words[0]);
        }
      }
    },
    openWhatsAppModal: function(userId){
      this.selectedUser = userId;
      this.$store.dispatch('openModal', 'sendWhatsApp');
    },
    openSmsModal: function(userId){
      this.selectedUser = userId;
      this.$store.dispatch('openModal', 'sendSms');
    },
    getMore: function () {
      this.filter.limit += 12;
      this.getOrderList();
    },
    getOrderList: function () {
      this.$store.dispatch('getOrderList', this.filter);
    },
    exportExcel: function () {
      this.$store.dispatch('getOrderExport', this.filter).then((res) => {
        if(res.data.result == 'ok') {
          let link = document.createElement('a');
          link.href = res.data.data.link;
          link.setAttribute('DOWNLOAD', res.data.data.filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.$toast.success(res.data.message);
        }else {
          this.$toast.success(res.data.message);
        }
      });
    },
  },
}
</script>
